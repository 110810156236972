/* 主题 */
$base: #C30000;
$desc: #9A9A9B;
$confirm: #2A8FFE;
$line-color: #EFEFEF;
$bg-color: #FFF;
$disable: #CDCDCD;


/** 文字 */

/** 主要文字 */
$main: #1E1F20;

/* 次要文字 */
$second: #777777;

/* 替代文字 */
$alter: #C0C0C0;

/**
  盒子px转rem
 */
@function remB($px) {
    @return ($px/75) * 1rem;
}
/**
    字号px转rem
*/
@function remF($px) {
    @return ($px/64) * 0.853333333rem;
}

@mixin flex-center($direction: row) {
    display: flex;
    justify-content: center;
    align-items: center;
    @if $direction != row {
        flex-direction: $direction;
    }
}

@mixin desc-words($font-size: 20) {
    font-size: remB($font-size);
    color: $desc;
}

