@import '../../styles/index.scss';

.wrapper {
    margin-top: remB(140);
    .steps {
        margin-top: remB(40);
        padding: 0 remB(40);
    }
    .desc {
    }

    .fill-again {
        color: #64affb;
        margin-left: remB(8);
        text-decoration: underline;
    }
}
