@import '../../styles/index.scss';

.link {
    display: flex;
    align-items: center;
    font-size: remF(30);

    .linkIcon {
        width: remB(48);
        height: remB(48);
    }

    .link-a {
        font-size: remF(30);
        color: $base;
        margin-left: remB(25);
    }
}


.title {
    font-size: remF(32);
    margin-top: remB(56);
    color: #030319;
    font-weight: 600;

    @include flex-center();
}


.content {
    margin-top: remB(48);
    overflow: scroll;
    padding: remB(50);
    height:80%;
}
