@import '../../styles/index.scss';

// .container {
//     display: flex;
//     align-items: center;
// }

.example {
    margin-top: remB(20);
    .example-img {
        width: remB(220);
        border-radius: remB(6);
    }

    .ant-upload-select-picture-card {
        width: 160px;
    }
}

.custom-upload {
    margin-top: remB(20);
}

.upload {
    margin-top: remB(32);
    width: remB(300);
    height: remB(300);
    border: 1px dashed #d9d9d9;
    position: relative;
    text-align: center;

    .input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 100;
    }

    .spin {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
    }
    .img {
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
    }

    .placeholder {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-flow: column;
        justify-content: space-between;
    }
}

.remarks-container {
    margin-top: remB(36);

    .remark {
        font-size: remF(28);
        color: #8c8c8c;
    }
}
