@import '../../styles/index.scss';

.img-wrapper {
    @include flex-center();

    .img {
        margin-top: remB(64);
        height: remB(96);
        width: remB(96);
    }
}

.desc {
    margin-top: remB(40);
    font-size: remF(28);
    color: #101010;
    padding: 0 remB(42);
}

.detail:after {
    content: '。';
}

.detail {
    margin-top: remB(32);
    font-size: remF(28);
    color: #101010;
    padding: 0 remB(42);
}

.btns {
    margin: remB(48) remB(30);
    // margin-top: remB(48);
    // margin-bottom: remB(48);
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
        @include flex-center();
        flex: 1;
    }

    // .left,
    // .right {
    //     width: remB(248);
    //     height: remB(88);
    // }

    .left {
        // margin-left: remB(48);
        text-shadow: none;
        box-shadow: none;
        border: none;
        font-size: remF(32);
        background-color: #fff;
        color: $base;
        @include flex-center();
        border: 1px solid $base;
        border-radius: remB(15);
        padding: remB(5) remB(0);
    }

    .right {
        margin-right: remB(48);
        text-shadow: none;
        box-shadow: none;
        border: none;
        margin-left: remB(38);
        font-size: remF(32);
        background-color: $base;
        color: #fefefe;
        @include flex-center();
        border-radius: remB(20);
    }
}
