@import '../../styles/index.scss';

.title-wrapper {
    display: inline-flex;
    align-items: center;
    .title {
        margin-right: remB(12);
    }
    .icon {
        color: rgba(0, 0, 0, 0.4);
        svg {
            width: remB(25);
        }
    }
}
.modal-info {
    width: remB(622) !important;
    .content {
        white-space: pre-line;
    }
    span[role='img'] {
        display: none !important;
    }
    div[class='ant-modal-content'] {
        border-radius: remB(20) !important;
        border: 1px solid transparent;
        div[class='ant-modal-body'] {
            padding: remB(48);
            display: flex;
            flex-direction: column;
            align-items: center;
            div[class='ant-modal-confirm-body'] {
                font-family: PingFang SC;
                font-style: normal;
                font-weight: normal;
                font-size: remF(30);
                color: #000000;
            }
            button[type='button'] {
                width: remB(510);
                height: remB(88);
                background: #ffffff;
                border: remB(2) solid #c30000;
                box-sizing: border-box;
                border-radius: remB(20);
                font-family: PingFang SC;
                font-style: normal;
                font-weight: 600;
                font-size: remF(32);
                text-align: center;

                color: #c30000;
            }
        }
    }
}

.wrapper {
    padding: remB(30);
}

.skeleton {
    padding: 0 remB(30);

    .skeleton-ul {
        // li {
        //     margin-top: 20px;
        //     height: 20px;
        // }
    }
}

.link {
    display: flex;
    // align-items: center;
    // padding: 0 remB(24);
    font-size: remF(30);
    margin-bottom: remB(26);

    .linkIcon {
        width: remB(48);
        height: remB(48);
    }

    .link-a {
        font-size: remF(30);
        color: $base;
        margin-left: remB(25);
    }
}

.basic-info {
    height: remB(100);
    display: flex;
    align-items: center;
    margin: 0 remB(32);
    color: #000;
    font-size: remB(36);
    padding: remB(24) 0 0;
    border-bottom: 2px solid #f5f5f5;
    font-weight: 600;
    margin-bottom: 32px;
}

.form-wrapper {
    background-color: #fff;
    padding: 0 remB(32);
}

.footer {
    margin-top: remB(80);
    padding: 0 remB(24);
    height: remB(88);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    position: relative;

    .back {
        text-shadow: none;
        box-shadow: none;
        border: none;
        height: 100%;
        font-size: remF(32);
        background-color: #fff;
        color: $base;
        width: remB(200);
        @include flex-center();
        border: 1px solid $base;
        border-radius: remB(20);
    }

    .forward {
        text-shadow: none;
        box-shadow: none;
        border: none;
        height: 100%;
        margin-left: remB(38);
        font-size: remF(32);
        background-color: $base;
        color: #fefefe;
        width: remB(400);
        @include flex-center();
        border-radius: remB(20);
    }

    .disable {
        background-color: #cdcdcd;
        color: #fefefe;
        border: transparent;
    }
}
