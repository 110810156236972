@import '../../styles/index.scss';

@media (max-width: 576px) {
    .wrapper {
        position: absolute;
        width: 100%;
        top: 0;
        height: remB(100);
        overflow: hidden;
        .mantle {
            top: remB(100);
            position: absolute;
            height: 100%;
            width: 100%;
            z-index: 999;
            background-color: rgba($color: #000000, $alpha: 0.7);
            .select-list {
                position: absolute;
                right: 0;
                top: 0;
                .option {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: remB(85);
                    width: remB(250);
                    font-family: PingFang SC;
                    font-style: normal;
                    font-weight: 500;
                    border-top: remB(2) solid #f5f5f5;
                    background-color: #fff;
                }
            }
        }
        .headerOperation {
            height: remB(100);
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-left: remB(56);
            margin-right: remB(56);
            border-bottom: remB(2) solid #f5f5f5;
            button {
                padding: 0;
            }
            .back {
                text-shadow: none;
                box-shadow: none;
                border: none;
                height: 100%;
                background-color: #fff;
                @include flex-center();
                border-radius: remB(20);
                font-family: PingFang SC;
                font-style: normal;
                font-weight: normal;
            }
            .switch-lang {
                text-shadow: none;
                box-shadow: none;
                border: none;
                height: 100%;
                background-color: #fff;
                @include flex-center();
                border-radius: remB(20);
                font-weight: 500;
                font-family: PingFang SC;
                font-style: normal;
            }
        }
    }
}

@media (min-width: 576px) {
    .wrapper {
        position: absolute;
        width: remB(700);
        top: 0;
        height: remB(100);
        overflow: hidden;
        .mantle {
            top: remB(100);
            position: absolute;
            height: 100%;
            width: 100%;
            z-index: 999;
            background-color: rgba($color: #000000, $alpha: 0.7);
            .select-list {
                position: absolute;
                right: 0;
                top: 0;
                .option {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: remB(85);
                    width: remB(250);
                    font-family: PingFang SC;
                    font-style: normal;
                    font-weight: 500;
                    border-top: remB(2) solid #f5f5f5;
                    background-color: #fff;
                }
            }
        }
        .headerOperation {
            height: remB(100);
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-left: remB(56);
            margin-right: remB(56);
            border-bottom: remB(2) solid #f5f5f5;
            button {
                padding: 0;
            }
            .back {
                text-shadow: none;
                box-shadow: none;
                border: none;
                height: 100%;
                background-color: #fff;
                @include flex-center();
                border-radius: remB(20);
                font-family: PingFang SC;
                font-style: normal;
                font-weight: normal;
            }
            .switch-lang {
                text-shadow: none;
                box-shadow: none;
                border: none;
                height: 100%;
                background-color: #fff;
                @include flex-center();
                border-radius: remB(20);
                font-weight: 500;
                font-family: PingFang SC;
                font-style: normal;
            }
        }
    }
}
