@import '../../styles/index.scss';

.title {
    font-size: remF(32);
    font-weight: 600;
    margin-top: remB(56);
    @include flex-center();
}

.content {
    padding: 0 remB(50) remB(100);
    overflow-y: scroll;
    color: $main;
    margin-top: remB(20);
    margin-bottom: remB(30);
    font-size: remF(28);
}

.btn-wrapper {
    margin-bottom: remB(104);
    padding: remB(20) remB(56);

    .btn {
        padding: remB(20) remB(20);
        font-size: remB(28);
        @include flex-center();
        font-weight: 600;
        color: #fff;
        border-radius: remB(20);

        &.btn-active {
            background-color: $base;
        }

        &.btn-disable {
            background-color: #cdcdcd;
        }
    }
}
