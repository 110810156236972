@import '../../styles/index.scss';

.wrapper {
    margin-top: remB(100);
    padding: 0 remB(50);
    .verifi-logo {
        width: remB(72);
        height: remB(72);
        margin-top: remB(55);
    }
    .underlane {
        margin-top: remB(16);
        margin-bottom: remB(16);
        height: remB(6);
        width: remB(56);
        background-color: #c30000;
    }
    .sms-title {
        margin-top: remB(104);
        font-family: PingFang SC;
        font-style: normal;
        font-size: remF(52);
        color: #1e1f20;
        font-weight: 600;
        margin-bottom: 0;
    }
    .code-input {
        width: remB(638);
        height: remB(96);
        background-color: #f6f6f7;
        mix-blend-mode: normal;
        border-radius: remB(20);
        border: none;
        input {
            background-color: #f6f6f7;
        }
        .input-icon {
            width: remB(40);
        }
    }
    .sms-reminder {
        color: $desc;
        font-size: remF(28);
    }

    .sms-confirm-btn {
        margin-top: remB(50);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .next-step-btn {
        margin-top: remB(80);
        background-color: #c30000;
        color: #fff;
        height: remB(88);
        width: remB(638);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: remF(32);
        border-color: transparent;
        border-radius: remB(20);
        transition: 0.2s;
    }

    .next-step-btn-disable {
        background: #cdcdcd;
    }

    .send-code-button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: remB(60);
        font-size: remF(28);
    }
    .countdown {
        color: #8f92a1;
    }

    .resendSms {
        color: #373737;
    }
}
