@import '../../styles/index.scss';

.wrapper {
    margin-top: remB(100);
    padding: 0 remB(50);

    .product-logo {
        width: remB(72);
        height: remB(72);
        margin-top: remB(55);
    }

    .underlane {
        margin-top: remB(16);
        margin-bottom: remB(16);
        height: remB(6);
        width: remB(56);
        background-color: #c30000;
    }

    .title {
        margin-top: remB(104);
        font-family: PingFang SC;
        font-style: normal;
        font-size: remF(52);
        color: #1e1f20;
        font-weight: 600;
        margin-bottom: 0;
    }

    .subtitle {
        color: $desc;
        font-size: remF(28);
    }

    .code-input {
        width: remB(638);
        height: remB(96);
        background-color: #f6f6f7;
        mix-blend-mode: normal;
        border-radius: remB(20);
        border: 1px solid transparent;
        input {
            background-color: #f6f6f7;
        }
        .input-icon {
            width: remB(40);
        }
    }

    .code-input-error {
        border-color: #c30000;
        transition: all 0.5s;
    }

    .error-msg {
        color: #c30000;
        font-size: remF(26);
        margin-top: remB(8);
        transition: 0.5s;
    }

    .next-step-btn {
        margin-top: remB(36);
        background-color: #c30000;
        color: #fff;
        height: remB(88);
        width: remB(638);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: remF(32);
        border-color: transparent;
        border-radius: remB(20);
        transition: 0.2s;
    }

    .next-step-btn-disable {
        background: #cdcdcd;
    }
}