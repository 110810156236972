@import '../../styles/index.scss';

.confirm-dialog-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 199;
    background-color: rgba(0, 0, 0, 0.4);
    @include flex-center();

    .confirm-modal {
    }
}
