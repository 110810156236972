@import './styles/index.scss';

a {
    // color: #fff;
}
button {
    outline: none;
}

.form-item {
    padding: 0px 0px !important;
    margin-bottom: 0 !important;
    background-color: #fff !important;
}

// .ant-form-item-control {
//     margin-bottom: 0.853333333rem;
// }

.bottom-drawer-has-radius {
    .ant-drawer-content {
        border-radius: 20px 20px 0 0;
    }
}

html,
body {
    // height: -moz-available; /* WebKit-based browsers will ignore this. */
    // height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    // height: fill-available;
    overflow-x: hidden;
    height: 100%;
}

.vertical-radio-group {
    label {
        display: flex;
        line-height: remB(60);
        align-items: flex-start;

        > span:first-child {
            line-height: remB(60);
            height: remB(60);
            display: inline-flex;
            align-items: center;

            .ant-radio-input {
                top: initial;
                right: initial;
                bottom: initial;
                left: initial;
            }
        }

        > span:last-child {
            word-break: break-all;
            width: remB(600);
            display: inline-block;
            white-space: normal;
        }
    }
}

// 文字换行
.card-pre-wrap {
    white-space: pre-wrap;
}

.text-decoration-underline {
    text-decoration: underline;
}

.padding-left-20 {
    padding-left: remB(20);
}
.padding-left-40 {
    padding-left: remB(40);
}
.padding-left-60 {
    padding-left: remB(60);
}
.padding-left-80 {
    padding-left: remB(80);
}

.text-align-center {
    text-align: center;
}
.text-align-left {
    text-align: left;
}
.text-align-right {
    text-align: right;
}

.font-weight-bold {
    font-weight: bold;
}
.font-weight-800 {
    font-weight: 800;
}

.font-size-22 {
    font-size: remF(22);
}
.font-size-24 {
    font-size: remF(24);
}
.font-size-26 {
    font-size: remF(26);
}
.font-size-28 {
    font-size: remF(28);
}
.font-size-30 {
    font-size: remF(30);
}
.font-size-32 {
    font-size: remF(32);
}

.font-size-34 {
    font-size: remF(34);
}

.font-size-36 {
    font-size: remF(36);
}

.font-size-38 {
    font-size: remF(38);
}

.font-size-40 {
    font-size: remF(40);
}
.font-size-42 {
    font-size: remF(42);
}
.font-size-44 {
    font-size: remF(44);
}
