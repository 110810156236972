@import '../../styles/index.scss';

.drawer {
    > div:last-child {
        height: remB(700) !important;
        > div {
            background-color: transparent;
            > div {
                background-color: transparent;
                > div {
                    padding: 0;
                    background-color: #fff;
                    border-top: 1px solid #fff;
                    border-top-left-radius: remB(18);
                    border-top-right-radius: remB(18);
                    .header {
                        height: remB(56);
                        display: flex;
                        justify-content: center;
                        .line {
                            margin-top: remB(30);
                            height: remB(6);
                            width: remB(76);
                            border-radius: remB(10);
                            background-color: #ebebed;
                        }
                    }
                    .title {
                        height: remB(56);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: remB(48);
                        font-family: PingFang SC;
                        font-style: normal;
                        font-weight: 600;
                        font-size: remF(32);
                        color: #1e1f20;
                    }
                    .container {
                        height: remB(538);
                        overflow: scroll;
                    }
                    .select {
                        background: #f6f6f7;
                    }
                    .option {
                        height: remB(120);
                        display: flex;
                        justify-content: space-between;
                        padding: 0 remB(56);

                        > span {
                            display: flex;
                            align-items: center;
                            font-size: remF(28);
                            font-family: PingFang SC;
                            font-style: normal;
                            font-weight: normal;
                        }
                        > span:last-child {
                            color: #8f92a1;
                        }
                        > span:first-child {
                            color: #030319;
                        }
                    }
                }
            }
        }
    }
}
.login-wrap {
    margin-top: remB(100);
    padding: 0 remB(56);
    .login-logo {
        width: remB(72);
        height: remB(72);
        margin-bottom: remB(24);
        margin-top: remB(55);
    }
    .welcome-word {
        font-size: remF(54);
        margin-bottom: 0;
        height: remB(80);
    }
    .welcome-words {
        font-size: remF(54);
        margin-bottom: 0;
        height: remB(80);
    }
    .underlane {
        margin-top: remB(16);
        height: remB(6);
        width: remB(56);
        background-color: #c30000;
    }
    .tips {
        color: #8f92a1;
        font-size: remF(28);
        margin-top: remB(120);
    }
    .tel {
        margin-top: remB(8);
        display: flex;
        .select {
            background: #f6f6f7;
            mix-blend-mode: normal;
            border-radius: remB(20);
            width: remB(214);
            height: remB(96);
            display: flex;
            align-items: center;
            .country-code {
                font-family: PingFang SC;
                font-style: normal;
                font-weight: normal;
                font-size: remF(32);
                color: #8f92a1;
                margin: 0 remB(19.67) 0 remB(32);
                width: remB(90);
            }
            img {
                height: remB(10);
            }
        }
        .input {
            background: #f6f6f7;
            mix-blend-mode: normal;
            border: none;
            border-radius: remB(20);
            transition: border ease-in-out 0.3s;
            box-sizing: border-box;
            width: remB(440);
            height: remB(96);
            margin-left: remB(20);
            padding: 0 remB(20);
            font-size: 14px;
            line-height: 1.5715;
            &:hover {
                border: 3px solid #d1e9ff;
            }
        }
    }

    .next-step-btn {
        margin-top: remB(80);
        background-color: #c30000;
        color: #fff;
        height: remB(88);
        width: remB(638);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: remF(32);
        border-color: transparent;
        border-radius: remB(20);
        transition: 0.2s;
    }

    .next-step-btn-disable {
        background: #cdcdcd;
    }
    .check {
        margin-top: remB(105);
        .check-text {
            font-family: PingFang SC;
            font-style: normal;
            font-weight: normal;
            font-size: remF(28);
            color: #777777;
        }
    }

    .clause-wrapper {
        width: 96%;
        margin-left: remB(15);
        font-family: PingFang SC;
        font-style: normal;
        font-weight: normal;
        font-size: remF(28);
        color: #777777;
        .show-clause {
            color: #007aff;
        }
    }
}

.confirm-dialog-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    @include flex-center();

    .confirm-modal {
        background-color: #fff;
        width: remB(580);
        border-radius: remB(40);
        overflow: hidden;
        // height: remB(600);
        // display: flex;

        .content {
            padding: remB(50);
            .title {
                font-size: remF(30);
                @include flex-center();
            }
            .desc {
                color: #8e97a2;
                margin-top: remB(20);
                margin-bottom: remB(0);
                font-size: remF(20);
            }
        }
        .confirm {
            border-top: 1px solid $line-color;
            @include flex-center();
            padding: remB(40) remB(40);
            height: remB(80);
            margin-bottom: remB(10);

            > div {
                margin-left: remB(20);
                flex: 1;

                > span {
                    color: $confirm;
                }
            }
        }

        .btns {
            height: remB(100);
            display: flex;
            justify-content: space-evenly;

            > div {
                flex: 1;
                @include flex-center();
            }
            .nope {
                border: 1px solid $line-color;
            }

            .ok {
                border: 1px solid $line-color;
                color: #2a8ffe;
                border-left: none;
            }
        }
    }
}
