@import '../../styles/index.scss';
@media (max-width: 576px) {
    .container {
        min-height: 100%;
        width: 100%;
        background-color: $bg-color;
        padding-bottom: remB(400);
        padding-top: remB(125);
        position: relative;
        
    }
}

@media (min-width: 576px) {
    .container {
        min-height: 100%;
        width: remB(700);
        background-color: $bg-color;
        padding-bottom: remB(400);
        margin: 0 auto;
        padding-top: remB(125);
        position: relative;
    }
}
