@import '../../styles/index.scss';

.container {
    background-color: #000;
    width: 100%;
    height: 100%;

    .back {
        position: absolute;
        top: remB(40);
        left: remB(30);
        color: #fff;
        font-weight: bold;
        z-index: 100;
    }

    .shadow-layer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;
        overflow: hidden;

        .capture-rectangle {
            margin: remB(200) auto 0;
            width: remB(700);
            height: remB(450);
            // width: remB(350);
            // height: remB(250);
            border: 1px solid #fff;
            border-radius: remB(20);
            z-index: 2;
            box-shadow: 0 0 0 remB(1000) rgba(0, 0, 0, 0.7);
        }

        .hold-tips {
            background-color: rgba(0, 0, 0, 0.6);
            color: #e1e1e1;
            font-size: remF(24);
            display: flex;
            align-items: center;
            justify-content: center;
            width: remB(300);
            margin: remB(30) auto 0;
            border-radius: remB(20);
        }
    }

    .tips {
        background-color: #333;
        color: #fff;
        font-size: remF(24);
        display: flex;
        align-items: center;
        justify-content: center;
        width: remB(500);
        margin: remB(30) auto 0;
        border-radius: remB(20);
    }

    .gallery-container {
        position: relative;
        .input {
            position: absolute;
            top: remB(66);
            left: remB(40);
            width: remB(100);
            height: remB(100);
            opacity: 0;
            z-index: 2;
        }
        .icon {
            margin-top: remB(100);
            margin-left: remB(40);
            color: #fff;
            width: remB(100);
            height: remB(100);
            font-size: remF(40);
        }
    }
}
