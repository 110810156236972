@import '../../../../styles/index.scss';

.select-input {
    margin: remB(32) 0 0;
    background-color: #f6f6f7;
    border-radius: remB(20);
    display: flex;
    align-items: center;
    font-size: remF(32);
    padding: remB(22) 0 remB(22) remB(22);
    position: relative;

    .placeholder {
        color: $alter;
    }

    .select-rec {
        width: remB(16.67);
        height: remB(10);
        margin-left: auto;
        margin-right: remB(43);
    }


    .selected-items {
        max-width: remB(498);
        white-space: normal;

        .item {
            border: 1px solid $base;
            padding: remB(2) remB(12);
            color: $base;
            border-radius: remB(12);
            font-size: remB(28);
            line-height: remB(44);
            background-color: #F7DBDB;
            margin-right: remB(26);
            margin: remB(7) remB(4) remB(7) 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            height: remB(48);
            width: fit-content;
            display: inline-block;
        }
    }
}

.search-input {
    margin: remB(26) remB(56);
    border-radius: 0.266666667rem;
    background: #F6F6F7;
    color: #1E1F20;
    font-size: 0.426666667rem;
    padding: remB(12);
    display: flex;

    .selected-items {
        max-width: remB(498);
        overflow-x: scroll;

        .item {
            border: 1px solid $base;
            padding: remB(2) remB(12);
            color: $base;
            border-radius: remB(12);
            font-size: remB(28);
            line-height: remB(44);
            background-color: #F7DBDB;
            margin-right: remB(26);
            margin: remB(7) remB(4) remB(7) 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            height: remB(48);
            width: fit-content;
        }
    }

    .input-wrap {
        > input {
            border: none;
            background: #F6F6F7;
            color: #1E1F20;
            max-width: remB(240);
        }
    }

    .close {
        height: remB(32);
        width: remB(32);
    }
}

.popup-container {
    padding: 0 0 constant(safe-area-insert-bottom, remB(62));
    padding: 0 0 env(safe-area-insert-bottom, remB(62));
    overflow: hidden;
    height: 100%;

    .tips {
        margin-top: remB(56);
        color: #030319;
        font-size: remF(32);
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;

        .selected-count {
            margin-left: remB(12);
            color: $base;
        }

        .confirm-text {
            position: absolute;
            right: remB(56);
            font-size: remB(32);
            color: $base;
        }
    }

    .options {
        height: 15rem - remB(180);
        padding: 0 remB(56) remB(60);
        overflow-y: scroll;
        // overflow-y: scroll;
        // overflow-x: hidden;

        ::-webkit-scrollbar {
            background-color: #1E1F20;
        }

        ::-webkit-scrollbar:vertical {
            width: 12px;
        }

        ::-webkit-scrollbar:horizontal {
            height: 12px;
        }

        ::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, .5);
            border-radius: 10px;
            border: 2px solid #ffffff;
        }

        ::-webkit-scrollbar-track {
            border-radius: 10px;
            background-color: #ffffff;
        }

        .option {
            height: remB(120);
            border-bottom: 1px solid #f2f2f2;
            display: flex;
            align-items: center;
            color: #030319;
            -moz-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
            -khtml-user-select: none;
            user-select: none;

            .picked {
                width: remB(32);
                height: remB(32);
                margin-left: auto;
            }
        }
    }
}
