@import '../../styles/index.scss';
.container {
    margin-top: remB(52);
}
.header {
    display: flex;
    align-items: center;
    padding: 0 remB(56);

    .logo {
        width: remB(210);
        // height: remB(44);
    }

    .title {
        margin-left: remB(16);
        font-size: remF(32);
    }

    .percent {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: #8f92a1;
        font-size: remF(28);
    }
}

.progress {
    height: remB(16);
    @include flex-center();
    position: relative;
    border-radius: remB(8);
    // overflow: hidden;
    margin: remB(20) remB(56) 0;

    .done {
        height: 100%;
        background-color: #e84035;
        border-radius: remB(8);
    }

    .remaining {
        height: 100%;
        background-color: #f6f6f7;
        border-radius: remB(8);
    }
}
