@import '../../styles/index.scss';
.wrapper {
    padding: 0 remB(93) remB(100);

    .avatar-wrapper {
        @include flex-center();

        .avatar {
            width: remB(131.16);
            height: remB(131.16);
            margin-top: remB(169.36);
            text-align: center;
        }
    }

    .tel {
        margin-top: remB(33.37);
        @include flex-center();
        font-weight: 600;
        font-size: remB(34);
        line-height: remB(48);
    }

    .tips-wrapper {
        margin-top: remB(72);
        font-size: remB(22);
        line-height: remB(40);

        color: rgba(0, 0, 0, 0.4);
        display: flex;
        align-items: center;

        .warning {
            width: remB(26.25);
            height: remB(26.25);
        }

        .tips {
            margin-left: remB(11.87);
        }
    }

    .options {
        margin-top: remB(30.87);

        .openact {
            border: 1px solid #c4c4c4;
            border-radius: remB(12);
            display: flex;
            align-items: center;
            // height: remB(130);
            padding: remB(12) 0;
            position: relative;

            .icon {
                margin-left: remB(19.47);
                width: remB(65.63);
                height: remB(65.63);
            }

            .content {
                margin-left: remB(19.47);

                .title {
                    font-weight: 600;
                    font-size: remB(32);
                    line-height: remB(48);
                }
                .desc {
                    font-size: remB(22);
                    line-height: remB(40);
                    font-weight: normal;
                }
            }

            .right-icon {
                margin-left: auto;
            }
        }
    }
}

.completed-wrapper {
    padding: 0 remB(93) remB(100);

    .avatar-wrapper {
        @include flex-center();

        .avatar {
            width: remB(131.16);
            height: remB(131.16);
            margin-top: remB(169.36);
            text-align: center;
        }
    }

    .welcome {
        margin-top: remB(33.37);
        @include flex-center();

        font-weight: 600;
        font-size: remB(34);
        line-height: remB(48);
    }

    .remind {
        @include flex-center();
        margin-top: remB(72);

        font-weight: 600;
        font-size: remB(34);
        line-height: remB(48);
    }
}
