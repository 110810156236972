@import '../../styles/index.scss';

// .container {
//     display: flex;
//     align-items: center;
// }

.example {
    margin-top: remB(20);
    .example-img {
        width: remB(220);
        border-radius: remB(6);
    }

    .ant-upload-select-picture-card {
        width: 160px;
    }
}

.custom-upload {
    margin-top: remB(20);
}

.upload {
    margin-top: remB(32);
    position: relative;
    text-align: center;

    .img-container {
        display: grid;
        grid-template-columns: remB(300) remB(300);
        gap: remB(30);
        .img {
            // position: absolute;
            // top: 50%;
            // left: 50%;
            // transform: translate(-50%, -50%);
            position: relative;
            border: 1px dashed #d9d9d9;
            width: remB(300);
            height: remB(300);
            .delete-img {
                font-size: 20px;
                position: absolute;
                top: 0;
                right: 0;
                transform: translate(30%, -30%);
            }
        }
    }

    .input-container {
        position: relative;
        border: 1px dashed #d9d9d9;
        .input {
            z-index: 1000000;
            width: remB(300);
            height: remB(300);
            opacity: 0;
        }
        .placeholder {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            flex-flow: column;
            justify-content: space-between;
            pointer-events: none;
        }
    }

    .spin {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
    }
}

.remarks-container {
    margin-top: remB(36);

    .remark {
        font-size: remF(28);
        color: #8c8c8c;
    }
}
