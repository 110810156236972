@import '../../../../styles/index.scss';

.single-select-container {
    .select-value {
        margin-top: remB(24);
        height: remB(96);
        background-color: #f6f6f7;
        border-radius: remB(20);
        display: flex;
        align-items: center;
        font-size: remF(32);
        padding-left: remB(32);
        position: relative;

        > span {
            max-width: remB(498);
            text-overflow: ellipsis;
            white-space: nowrap; /* 不换行 */
            overflow: hidden;
        }

        .placeholder {
            color: $alter;
        }

        .select-rec {
            width: remB(16.67);
            height: remB(10);
            position: absolute;
            right: remB(43);
        }
    }

}

.select-container {
    padding: 0 0 remB(62);
    overflow: hidden;

    .tips {
        margin-top: remB(56);
        margin-bottom: remB(54);
        color: #030319;
        font-size: remF(32);
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .options-container {
        height: remB(500);
        padding: 0 remB(56) remB(40);
        overflow: scroll;
        // padding-bottom: remB(30);

        .option-card {
            height: remB(120);
            border-bottom: 1px solid #f2f2f2;
            display: flex;
            align-items: center;
            color: #030319;
        }
    }
}

.select-container {
    padding: 0 0 constant(safe-area-insert-bottom, remB(62));
    padding: 0 0 env(safe-area-insert-bottom, remB(62));
    overflow: hidden;
    height: 100%;

    .tips {
        margin-top: remB(56);
        color: #030319;
        font-size: remF(32);
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;

        .selected-count {
            margin-left: remB(12);
            color: $base;
        }

        .confirm-text {
            position: absolute;
            right: remB(56);
            font-size: remB(32);
            color: $base;
        }
    }

    .options {
        height: 10.333333333rem - remB(190);
        padding: 0 remB(56) remB(60);
        overflow-y: scroll;
        // overflow-y: scroll;
        // overflow-x: hidden;

        ::-webkit-scrollbar {
            background-color: #1E1F20;
        }

        ::-webkit-scrollbar:vertical {
            width: 12px;
        }

        ::-webkit-scrollbar:horizontal {
            height: 12px;
        }

        ::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, .5);
            border-radius: 10px;
            border: 2px solid #ffffff;
        }

        ::-webkit-scrollbar-track {
            border-radius: 10px;
            background-color: #ffffff;
        }

        .option {
            height: remB(120);
            border-bottom: 1px solid #f2f2f2;
            display: flex;
            align-items: center;
            color: #030319;
            -moz-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
            -khtml-user-select: none;
            user-select: none;

            .picked {
                width: remB(32);
                height: remB(32);
                margin-left: auto;
            }
        }
    }
}

.search-input {
    margin: remB(26) remB(56);
    border-radius: 0.266666667rem;
    background: #F6F6F7;
    color: #1E1F20;
    font-size: 0.426666667rem;
    padding: remB(12);
    display: flex;

    .selected-items {
        max-width: remB(498);
        overflow-x: scroll;

        .item {
            border: 1px solid $base;
            padding: remB(2) remB(12);
            color: $base;
            border-radius: remB(12);
            font-size: remB(28);
            line-height: remB(44);
            background-color: #F7DBDB;
            margin-right: remB(26);
            margin: remB(7) remB(4) remB(7) 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            height: remB(48);
            width: fit-content;
        }
    }

    .input-wrap {
        > input {
            border: none;
            background: #F6F6F7;
            color: #1E1F20;
            max-width: remB(240);
        }
    }

    .close {
        height: remB(32);
        width: remB(32);
    }
}