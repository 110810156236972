@import '../../styles/index.scss';

@media (max-width: 576px) {
    .basic-wrapper {
        position: absolute;
        top: 0;
        width: 100%;
        min-height: 100%;
    }
}

@media (min-width: 576px) {
    .basic-wrapper {
        position: absolute;
        top: 0;
        width: remB(700);
        min-height: 100%;
        left: 50%;
        transform: translateX(-50%);
    }
}

// @media (min-width: 991px) {
//     .basic-wrapper {
//         @include wrapper-padding(300);
//     }
// }

// @media (min-width: 1199px) {
//     .basic-wrapper {
//         @include wrapper-padding(400,);
//     }
// }

// @media (min-width: 1599px) {
//     .basic-wrapper {
//         @include wrapper-padding(500);
//     }
// }
